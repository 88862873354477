import{popupConstants} from "../constants/popup.constants";

export const popupActions = {
    userinfo,
    clear,
    settings
};

function userinfo(info) {
    return { type:popupConstants.INFOPOPUP, info };
}
function settings(info) {
    return { type:popupConstants.SETTINGS_POPUP, info };
}

function clear() {
    return { type: popupConstants.CLEARPOPUP };
}

