
import "../css/dash.css";


import React, {forwardRef} from "react";
import { useDispatch,useSelector } from "react-redux";
import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";

import {alertActions} from '../actions';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Footer from "../Components /Footer";
import {Link} from "react-router-dom";




function  Clubs() {

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

  const dispatch = useDispatch();
  const message = useSelector((state) => state.alert);
  const marks = useSelector((state) => state.teacherReducer.ClubMarks);
  const netError = useSelector((state) => state.teacherReducer.netError);

    React.useEffect(() => {

        if(!marks && !netError){
            dispatch(teacherActions.getClubMarks())
        }

        dispatch(pagesActions.updatepage(pagesConstants.CLUBS));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })
    },[])


    const columns = [


        {
            title: "ID",
            field: "studId",
        },
        {
            title:"Name" ,
            field: "Name",
           // hidden:true
        },
        {
            title: "Class",
            field:"clazz" ,
        },
        {
            title:"Club" ,
            field:"ecName" ,
        },
    // {
    //     title:"" ,
    //         field:"ecKey" ,
    // },
        {
            title: "Effort",
            field: "effort",
        },
        {
            title: "Achievement",
            field: "achievement",
        },
        {
            title: "Marked?",
            field: "status",
        },
        {
            title: "Actions",
            field: "actions",
        }

    ];



    function submitMark(e) {
        let mark = JSON.parse(e.target.getAttribute("data-mark"));
        let  effort = document.getElementById(mark.markId+'EFF').value
        let  achievement = document.getElementById(mark.markId+'ACHV').value
        let newMark ={...mark,effort,achievement}
        console.log(newMark)
        if(effort!==""&&achievement!==""){
            dispatch(alertActions.success("Working ,please wait"));
            dispatch(teacherActions.submitClubMark(newMark))
        }else{
            dispatch(alertActions.error("Please Select Both Effort and Achievement for "+mark.fname));
            setTimeout(() => {
                dispatch(alertActions.clear())
            }, 5000)
        }





    }

    return (
            <>
                <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                  <div className={"block-header "}>
                      <div className={"row clearfix  align-items-end  "}>

                          <div className={"col-6 text-bold  "}>
                              <strong><h2>Club Marks</h2></strong>
                          </div>


                      </div>
                  </div>
                  { message ? (
                      <div className={message.type + " my-1"}>
                          {" "}
                          <div className="popup"> {message.message}</div>
                      </div>) : ("")
                  }
                  {/*Breadcrumb*/}
                  <nav aria-label="breadcrumb" className="main-breadcrumb">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/home">
                              Home
                          </Link></li>
                          <li className="breadcrumb-item active" aria-current="page">Club Marks</li>
                      </ol>
                  </nav>
                  {/*Breadcrumb*/}


                  <div className="row mt-2 ">
                      <div className="col">
                          {marks&&marks.marks? <MaterialTable
                              title= {"Club Marks"}
                              icons={tableIcons}

                              data={marks.marks.map(mark=>{
                                  return(
                                      {
                                          ...mark,

                                  effort:<>
                                      <select name={mark.markId+"EFF"} className="col mb-2" id={mark.markId+"EFF"} >
                                          <option value="">--EFFORT--</option>
                                          <option value="A" selected={mark.effort==="A"}>A: Highly motivated student</option>
                                          <option value="B" selected={mark.effort==="B"}>B: Very active participant</option>
                                          <option value="C" selected={mark.effort==="C"}>C: Needs more encouragement</option>


                                      </select>
                                  </>,

                                  achievement:<>
                                      <select name={mark.markId+"ACHV"} className="col mb-2" id={mark.markId+"ACHV"} >
                                          <option value="">--ACHIEVEMENT--</option>
                                          <option value="1" selected={mark.achievement==="1"}>1: Outstanding student</option>
                                          <option value="2" selected={mark.achievement==="2"}>2: Active Participant</option>
                                          <option value="3" selected={mark.achievement==="3"}>3: Attendee</option>
                                          <option value="4" selected={mark.achievement==="4"}>4: Provincial Representative</option>
                                          <option value="5" selected={mark.achievement==="5"}>5: National Representative</option>
                                      </select>
                                  </>,  status:mark.status?(<div className="col"> <i className="fas fa-check-circle text-success fa-2x"/></div>):(<div className="col-6">
                                              <i className="fas fa-times-circle text-danger fa-2x"/></div>),

                                          actions:!mark.status?<><button className={"btn btn-small btn-primary"} data-mark={JSON.stringify(mark)} onClick={submitMark}>submit</button></>:
                                              <><button className={"btn btn-small btn-warning"} data-mark={JSON.stringify(mark)} onClick={submitMark}>alter</button></>}


                                  )
                              })
                              }
                              columns={columns}
                              options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                      //  backgroundColor:"darkgray",
                                      // color:"white",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  rowStyle:{
                                     
                                      fontSize:"smaller",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  pageSizeOptions: [5, 10, 20, 100]}}

                          />:<MaterialTable
                              title= "No Mark Schedule "
                              icons={tableIcons}
                              data={[]}
                              columns={columns}
                              options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                      //  backgroundColor:"darkgray",
                                      // color:"white",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  rowStyle:{

                                      fontSize:"smaller",
                                      paddingTop:0,
                                      paddingBottom:0
                                  },
                                  pageSizeOptions: [5, 10, 20, 100]}}
                          />
                          }
                      </div>



                  </div>
                      <Footer/>

                  </div>



            </>);

}

export default Clubs;
