import {
    userConstants
} from "../constants/user.constants";

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? {
    loggingIn: false,
    loggedIn: true,
    user
} : {};

export function authenticationReducer(state = initialState, action) {
    switch (action.type) {
        case userConstants.USER_LOGIN_REQUEST:
            return {
                loggingIn: true,
                    user: action.user
            };
        case userConstants.USER_LOGIN_SUCCESS:
            return {

                loggedIn: true,
                    loggingIn: false,
                    user: action.user
            };
        case userConstants.USER_LOGIN_FAILURE:
            return {
                ...state,
                loggedIn: false,
                    loggingIn: false,
                    error: action.error
            };

        case userConstants.SET_EMAIL_REQUEST:
            return {
                ...state,
                settingEmail: true,

            };
        case userConstants.SET_EMAIL_SUCCESS:
            return {
                ...state,
                settingEmail: false,
                    setEmail: true,
                    user: {
                        ...state.user,
                        email: action.email
                    }
            };
        case userConstants.SET_EMAIL_FAILURE:
            return {
                ...state,
                settingEmail: false,
                    setEmail: false,
                    error: action.error
            };
        case userConstants.USER_LOGOUT:
            return {};
        default:
            return state
    }
}