import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/login.css";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../actions/user.actions";
function Login() {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    setUser((user) => ({ ...user, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    // setSubmitted(true);
    //console.log(user)
    if (user.username && user.password) {
      e.preventDefault();
      dispatch(userActions.login(user));
    } else {
      alert(
        "oops, looks like you did not fill in all the required field try again"
      );
    }
  }
  const loading = useSelector((state) => state.authenticationReducer.loggingIn);
  const message = useSelector((state) => state.alert);

  return (
    <form className={"mt-5"} onSubmit={handleSubmit}>
      <h3>Log in</h3>

      {message ? (
        <div className={message.type + " my-1"}>
          {" "}
          <div className="popup"> {message.message}</div>
        </div>
      ) : (
        ""
      )}

      <div className="form-group">
        <label>Username</label>
        <input
          name="username"
          onChange={handleChange}
          type="text"
          className="form-control"
          placeholder="Enter username"
        />
      </div>

      <div className="form-group">
        <label>Password</label>
        <input
          name="password"
          onChange={handleChange}
          type="password"
          className="form-control"
          placeholder="Enter password"
        />
      </div>

      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="customCheck1"
          />
          <label className="custom-control-label" htmlFor="customCheck1">
            Remember me
          </label>
        </div>
      </div>

      <button type="submit" className="btn btn-dark btn-lg btn-block">
        {loading ? (
          <div
            className="spinner-grow text-light text-small"
            role="status"
          ></div>
        ) : (
          <div> Sign in</div>
        )}
      </button>
      <br />

      <Link to="/EJohns/AccountRecovery" className="text-dark">
        <p className="forgot-password text-right">
          Forgot <strong>password?</strong>
        </p>
      </Link>
    </form>
    // </div>
    //             </div> </div></div>
  );
}
export default Login;
