
import "../css/dash.css";


import React, {forwardRef, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
///import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";

import {alertActions} from '../actions';
import MaterialTable from "material-table";

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {Link} from "react-router-dom";
import Footer from "../Components /Footer";




function  ManageStudents() {

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };

    const dispatch = useDispatch();
    const tic = useSelector((state) => state.teacherReducer.TIC);
    const options = useSelector((state) => state.teacherReducer.options);
    const user = useSelector((state)=>state.authenticationReducer.user.userId)
    const message = useSelector((state) => state.alert);
    const students = useSelector((state) => state.teacherReducer.students);
    const netError = useSelector((state) => state.teacherReducer.netError);

    React.useEffect(() => {
        if(!students&&!netError){
            dispatch(teacherActions.getStudents())
        }
        if(!options && !netError) {
            dispatch(teacherActions.getOptions())
        }
        dispatch(pagesActions.updatepage(pagesConstants.SUPPORT));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })


    },[])

    const columns = [
        {
            title: "ID",
            field:"id" ,
        },
        {
            title:"Surname" ,
            field: "lname",
            // hidden:true
        },
        {
            title: "Name",
            field:"fname" ,
        },

        {
            title:"Class" ,
            field:"clazz" ,
        },
        {
            title:"D.O.B" ,
            field:"dob" ,
        },{
            title: "Gender",
            field: "sex",
        },
        {
            title: "Nat.ID",
            field: "idNumber",
        },
        {
            title: "Religion",
            field: "religion",
        }
    ];
    const [currentCsv,setCurrentCsv] = useState({
        csv:""
    });

    const [clazz,setClazz] = useState({
        selected:"ALL"
    });
    function handleChange(e){
        const { name,value } = e.target
        setClazz((clazz) => ({...clazz, [name]:value}))
    }

    function handleCsv (e){
        e.preventDefault()
        setCurrentCsv((currentCsv) => ({ csv:e.target.files[0]}))
        SubmitCsv()
    }
    function SubmitCsv() {
        let fileData = new FormData();
        fileData.append('file', currentCsv.csv);
        dispatch(teacherActions.uploadStudents(fileData))
    }




    function handlePdf(e) {
        let selected = clazz.selected;
        dispatch(alertActions.success("Working ,please wait"));
        dispatch(teacherActions.getPDFClassLists([selected]))
    }

    return (
        <>
            <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                <div className="block-header">
                    <div className="row clearfix">
                        <div className="col-md-6 col-sm-12">
                            <h2> Manage Students</h2>
                        </div>

                        <br/><br/>
                    </div>
                </div>

                { message ? (
                    <div className={message.type + " my-1"}>
                        {" "}
                        <div className="popup"> {message.message}</div>
                    </div>) : ("")
                }
                {/*Breadcrumb*/}
                <nav aria-label="breadcrumb" className="main-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/home">
                            Home
                        </Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Students</li>
                    </ol>
                </nav>
                {/*Breadcrumb*/}



            <div className="row justify-content-between align-content-center">

                {options&&options.subjects?(<>
                    <div className="col-6" >
                        <div className="container-fluid border bg-white p-3 rounded">
                            <div className="row">
                                <div className="col">
                                    <select className="col p-0 m-0" name="selected" onChange={handleChange}>
                                        <option value={"ALL"}>ALL CLASSES</option>

                                        {options.subjects.map(subject=>{
                                            return(
                                                <>
                                                    <option value={subject.clazz.clName}>{subject.clazz.clName}</option>
                                                </>
                                            )})}

                                     </select >
                                </div>
                                <div className="col-4"> <div className="btn btn-dark col" onClick={handlePdf}><small>PDF Report</small></div></div>
                            </div>
                        </div>



                    </div>
            </>):""}

                <div className="col-6 ">
                    <div className="container">
                        <div className="row align-content-center">
                            <div className="col m-1 p-0">
                                <Link to="/home/AddStudent">
                                <div className="btn btn-primary col"><small>ADD</small></div>
                                </Link>
                            </div>
                            <div className="col m-1 p-0">
                                <Link to="/home/EditStudent">
                                    <div className="btn btn-primary col"><small>EDIT</small></div>
                                </Link>
                            </div>
                            <div className="col m-1 p-0">
                                <input type="file"

                                       className="custom-file-input "
                                       id="file"
                                       name="file"
                                       onChange={handleCsv}
                                       hidden/>

                                <label className={"btn btn-primary border  col"} htmlFor="file"><small>UPLOAD</small></label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
                <div className="row mt-2 ">
                    <div className="col">
                        {students? <MaterialTable
                            title= {"Students"}
                            icons={tableIcons}
                            data={students}
                            columns={columns}
                            options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]}}


                        />:<MaterialTable
                            title= "Students"
                            icons={tableIcons}
                            data={[]}
                            columns={columns}
                            options={{ search: true, paging: true, filtering: false, exportButton: true,grouping: true, headerStyle:{
                                    //  backgroundColor:"darkgray",
                                    // color:"white",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                rowStyle:{

                                    fontSize:"smaller",
                                    paddingTop:0,
                                    paddingBottom:0
                                },
                                pageSizeOptions: [5, 10, 20, 100]}}

                        />
                        }
                    </div>



                </div>
                <Footer/>

            </div>



        </>);

}

export default ManageStudents;
