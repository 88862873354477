import {
    combineReducers
} from 'redux';

import {
    alert
} from './alert.reducer';
import {
    authenticationReducer
} from "./authentication.reducer";
import {
    popupReducer
} from "./popup.reducer";
import {
    pagesReducer
} from "./pages.reducer";
import {
   teacherReducer
} from "./teacher.reducer";
import {adminReducer} from "./admin.reducer";


const appReducer = combineReducers({
    alert,
    authenticationReducer,
    popupReducer,
    pagesReducer,
    teacherReducer,
    adminReducer
    

});

const rootReducer = (state, action) => {
    return appReducer(state, action)
}

export default rootReducer;