import React, { useState } from "react";
import { pagesConstants } from "../constants/pages.constants";
import { pagesActions } from "../actions/pages.actions";
import { useDispatch, useSelector } from "react-redux";

import { adminActions } from "../actions/admin.actions";

function AddPortalUser() {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(pagesActions.updatepage(pagesConstants.ADDPORTALUSER));
  }, []);
  let message = useSelector((state) => state.alert);

  let [user, setUser] = useState({
    username: "",
    email: "",
    enabled: "",
    BranchCode:"",
    Role:"",
  });

  const [updateButton, setUpdateButton] = useState({
    status: true,
  });

  function handleChange(e) {
    e.preventDefault();
    setUpdateButton((UpdateButton) => ({ status: false }));

    const { name, value } = e.target;

    let x = value
      .replace(/(^\s*)|(\s*$)/gi, "")
      .replace(/[ ]{2,}/gi, " ")
      .replace(/\n +/, "\n");

    setUser((user) => ({ ...user, [name]: x }));
  }

  function handleUpdate(e) {
    e.preventDefault();

    if (user.username.trim().length > 2) {
      dispatch(adminActions.newUser(user));
    } else {
      alert("username should have 3 or more characters");
    }
  }

  return (
    <React.Fragment>
      <form id="pUser" onSubmit={handleUpdate}>
        <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
          <div className="block-header">
            <div className="row clearfix">

            </div>
          </div>

          <div className="container-fluid">
            <div className="row clearfix justify-content-center ">
              <div className="col-xl-9 col-lg-8 col-md-7">
                <h5>
                  <strong>Add Portal User</strong>
                </h5>
                <div className="card">
                  <div className="header container ">
                    <br />
                    <h2>Basic Information</h2>
                  </div>
                  <div className="body">
                    <br />

                    <div className=" ">
                      <div className="row">
                        <div className="col">
                          {" "}
                          {message ? (
                            <div className={message.type + " my-1"}>
                              {" "}
                              <div className="popup"> {message.message}</div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="row justify-content-center ">
                        <div className="col-4 ">
                          <strong className="text-bold">Name</strong>
                        </div>
                        <input
                          placeholder="Username"
                          className="col-5 pl-3 mb-2 "
                          type="text"
                          name="username"
                          onChange={handleChange}
                          required
                          minLength={3}
                          trim
                        />
                      </div>
                      <div className="row  justify-content-center">
                        <div className="col-4">
                          <strong className="text-bold">Email</strong>
                        </div>
                        <input
                          placeholder="Email"
                          className="col-5 pl-3 mb-2"
                          type="email"
                          name="email"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="row  justify-content-center">
                        <div className="col-4">
                          <strong className="text-bold">Department</strong>
                        </div>
                        <select
                          className="form-group col-5 pl-3 mb-2 form-control"
                          name="BranchCode"
                          onChange={handleChange}
                          required
                        >
                          <option value="">-- Select Department --</option>

                        </select>
                      </div>


                      <div className="row  justify-content-center">
                        <div className="col-4">
                          <strong className="text-bold">Role</strong>
                        </div>
                        <select
                            className="form-group col-5 pl-3 mb-2 form-control"
                            name="Role"
                            onChange={handleChange}
                            required
                        >
                          <option value="">-- Select Role --</option>

                          <option value="3">Administrator</option>
                          <option value="1">Regular User </option>

                        </select>
                      </div>

                      <div className="row  justify-content-center">
                        <div className="col-4"></div>
                        <div className="col-5 p-0  mb-2 d-flex justify-content-end ">
                          {" "}
                          <button
                            type="submit"
                            className={
                              updateButton.status
                                ? "btn btn-success disabled btn-sm "
                                : " btn-sm btn btn-success"
                            }
                            disabled={updateButton.status}
                          >
                            Add User
                          </button>
                        </div>
                      </div>
                    </div>

                    <br />
                  </div>
                </div>
              </div>
            </div>{" "}
            <br />
            <br />
            <br />
          </div>
          <div className="row  m-0 mt-5">
            <div className="col">
              <p
                className=" text-muted text-center "
                style={{ fontSize: "small" }}
              >
                © 2021 Copyright ZB Information Services
              </p>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
export default AddPortalUser;
