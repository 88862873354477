export const pagesConstants = {

    HOME: 'HOME',
    SUPPORT: 'SUPPORT',
    ADDUSER: 'ADD_USER',
    ADDPORTALUSER: 'ADD_PORTAL_USER',
    PORTAL: 'PORTAL',
    REGISTER:'REGISTER',
    ARREARS:'ARREARS',
    SPORTS:'SPORTS',
    CLUBS:'CLUBS',
    DEPARTMENTS:'DEPARTMENTS',
    CONTACTS:'CONTACTS',
    MEDICALS:'MEDICALS',
    STUDENTSCOMMENTS:'STUDENTSCOMMENTS',
    CALENDAR:'CALENDAR',
    MANAGECALENDAR:'MANAGECALENDAR',
    MANAGENOTIFICATIONS:'MANAGENOTIFICATIONS',
    PROFILE:'PROFILE',
    TEACHERS:'TEACHERS',
    SCHEMA:'SCHEMA',
    REGISTRATION:'REGISTRATION'
};