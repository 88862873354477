import {
    adminConstants
} from "../constants/admin.constants";

const initialState = {
    resetingPin: false,
    pinId: 123,
    fetchingUsers: false
};

export function adminReducer(state = initialState, action) {
    switch (action.type) {
        case adminConstants.PORTAL_USER_RETRIEVE_REQUEST:
            return {
                ...state,
                fetchingUsers: true,

            };
        case adminConstants.PORTAL_USER_RETRIEVE_SUCCESS:
            return {
                ...state,
                fetchingUsers: false,
                    fetchedUser: true,
                    users: action.users
            };
        case adminConstants.PORTAL_USER_RETRIEVE_FAILURE:
            return {
                ...state,
                fetchingUsers: false,
                    fetchedUser: false,
                    error: action.error
            };


        case adminConstants.PORTAL_USER_UPDATE_REQUEST:
            return {
                ...state,
                updatingUser: true,

            };
        case adminConstants.PORTAL_USER_UPDATE_SUCCESS:

            return {
                ...state,
             //   users: state.users.map(user => (user === action.user.userId) ? user = action.user : user),
                    updatingUser: false,
                    updatedUser: action.user,

            };
        case adminConstants.PORTAL_USER_UPDATE_FAILURE:
            return {
                ...state,
                updatedUser: false,
                    updatingUser: false,
                    error: action.error
            };



        case adminConstants.PORTAL_USER_RESET_PIN_REQUEST:
            return {
                ...state,
                resetingPin: true,
                    pinId: action.user
            };
        case adminConstants.PORTAL_USER_RESET_PIN_SUCCESS:
            return {
                ...state,
                resetingPin: false,
                    resetpin: true,
                    pinId: action.user.userId

            };
        case adminConstants.PORTAL_USER_RESET_PIN_FAILURE:
            return {
                ...state,
                resetingPin: false,
                    resetpin: false,
                    error: action.error
            };
        case adminConstants.PORTAL_USER_RESET_PIN_CLEAR:
            return {
                ...state,
                resetingPin: false,
                    resetpin: false,
                    pinId: false,
                    error: action.error
            };


        case adminConstants.PORTAL_ACCOUNT_EDIT_REQUEST:
            return {
                ...state,
                editingAccount: true,

            };
        case adminConstants.PORTAL_ACCOUNT_EDIT_SUCCESS:
            return {
                ...state,
                editingAccount: false,
                    editedAccount: true,


            };
        case adminConstants.PORTAL_ACCOUNT_EDIT_FAILURE:
            return {
                ...state,
                editingAccount: false,
                    editedAccount: false,
                    error: action.error
            };
        case adminConstants.PORTAL_ACCOUNT_EDIT_CLEAR:
            return {
                ...state,
                editingAccount: false,
                    editedAccount: false,
                    error: ""
            };



        default:
            return state
    }
}