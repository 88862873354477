import {
  alertConstants
} from "../constants/alert.constants";

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'alert-success m-0 p-0 rounded text-center',
          message: action.message
      };
    case alertConstants.ERROR:
      return {
        type: 'alert-danger m-0 p-0 rounded text-center',
          message: action.message
      };

    case alertConstants.CLEAR:
      return {};
    default:
      return state
  }
}