import {
  popupConstants
} from "../constants/popup.constants";

export function popupReducer(state = {}, action) {
  switch (action.type) {
    case popupConstants.INFOPOPUP:
      return {

        infoPopup: true,
        settingsPopup: false,
        info: action.info
      };
    case popupConstants.SETTINGS_POPUP:
      return {
          InfoPopup: true,
          settingsPopup: true,
          settings: action.info
      };
    case popupConstants.CLEARPOPUP:
      return {
        InfoPopup: false,
        settingsPopup: false,
      };
    default:
      return state
  }
}