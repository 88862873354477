import {
  pagesConstants
} from "../constants/pages.constants";

export function pagesReducer(state = {
  activepage: pagesConstants.HOME
}, action) {
  switch (action.type) {
    case pagesConstants.HOME:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.TEACHERS:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.PROFILE:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.REGISTER:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.ARREARS:
      return {
        active_page: action.active_page,
      };
      case pagesConstants.SUPPORT:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.SPORTS:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.CLUBS:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.ADDUSER:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.PORTAL:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.ADDPORTALUSER:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.DEPARTMENTS:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.STUDENTSCOMMENTS:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.CONTACTS:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.MEDICALS:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.CALENDAR:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.MANAGECALENDAR:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.MANAGENOTIFICATIONS:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.SCHEMA:
      return {
        active_page: action.active_page,
      };
    case pagesConstants.REGISTRATION:
      return {
        active_page: action.active_page,
      };
    default:
      return state
  }
}