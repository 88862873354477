import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../actions/user.actions";
import logo from "../assets/compuLearnSource/EJohnsBadgeBlack.png";

function SettingsPopup(props) {
  let message = useSelector((state) => state.alert);
  const currentUser = useSelector((state) => state.authenticationReducer.user);
  //   const  editingAccount =useSelector(state=>state.dashReducer.editingAccount);
  const dispatch = useDispatch();

  let [accountDetails, setAccountDetails] = useState({
    username: "",
    email: "",
  });

  let [Credentials, setCredentials] = useState({
    passwordBefore: "",
    password1: "",
    password2: "",
  });

  const [credentialsButton, setCredentialsButton] = useState({
    status: true,
  });

  const [detailsButton, setDetailsButton] = useState({
    status: true,
  });

  const settingsPopup = useSelector(
    (state) => state.popupReducer.settingsPopup
  );
  const popupinfo = useSelector((state) => state.authenticationReducer.user);

  function handleCredentialsUpdate(e) {
    e.preventDefault();
    if (Credentials.password1 === Credentials.password2) {
      dispatch(
        userActions.updateUser({
          username: currentUser.username,
          curPassword: Credentials.passwordBefore,
          newPassword: Credentials.password1,
        })
      );
    } else {
      alert("the new and re-entered password do not match , Please try again");
    }
    setCredentialsButton((credentialsButton) => ({ status: true }));
    //     dispatch(userActions.updateUser({ ...popupinfo, ...user }));
  }

  function handleDetailsUpdate(e) {
    e.preventDefault();

    setDetailsButton((detailsButton) => ({ status: true }));
    dispatch(
      userActions.updateUser({
        curUsername: currentUser.username,
        email: accountDetails.email,
      })
    );
  }

  function handleDetailsChange(e) {
    e.preventDefault();
    setDetailsButton(() => ({ status: false }));
    const { name, value } = e.target;

    setAccountDetails((accountDetails) => ({
      ...accountDetails,
      [name]: value,
    }));
  }
  function handleCredentialsChange(e) {
    e.preventDefault();
    setCredentialsButton(() => ({ status: false }));
    const { name, value } = e.target;

    setCredentials((Credentials) => ({ ...Credentials, [name]: value }));
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter ">
          {settingsPopup ? (
            <>
              <div className="row align-items-center">
                <img src={logo} width="100" alt="zb logo" className="mx-3" />

                <div className="col ">
                  <strong className="mt-4 text-bold"> User Profile</strong>
                </div>
              </div>
            </>
          ) : (
            "E-Johns Portal"
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          {settingsPopup ? (
            <>
              <form onSubmit={handleDetailsUpdate}>
                <div className="row">
                  <div className="col">
                    {" "}
                    {message ? (
                      <div className={message.type + " my-1"}>
                        {" "}
                        <div className="popup"> {message.message}</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-4 pl-0">
                  <i class="fa fa-user"></i>{" "}
                  <strong className="text-bold">Basic Information</strong>
                </div>

                <div className="border  card ">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-4">
                        <strong className="text-bold">User Name</strong>
                      </div>
                      <input
                        placeholder="User Name"
                        className="col-5 pl-3 mb-2"
                        defaultValue={popupinfo.username}
                        type="text"
                        name="username"
                        disabled
                      />
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <strong className="text-bold">User Email</strong>
                      </div>
                      <input
                        className="col-5 pl-3 mb-2"
                        placeholder={popupinfo.email}
                        type="email"
                        name="email"
                        onChange={handleDetailsChange}
                        required
                        disabled

                      />
                    </div>
                    <div className="row pl-3  justify-content-end">
                      {" "}
                      <div className="col-2">
                        {" "}
                        <button
                          type="submit"
                          className={
                            detailsButton.status
                              ? "btn btn-success disabled btn-sm "
                              : " btn-sm btn btn-success"
                          }
                          //disabled={detailsButton.status}
                            disabled
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </form>
              <form onSubmit={handleCredentialsUpdate}>
                <div className="col-4 pl-0">
                  <i class="fa fa-gear"></i>{" "}
                  <strong className="text-bold"> Credentials</strong>
                </div>
                <div className="border  card ">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-4">
                        <strong className="text-bold">Current Password</strong>
                      </div>
                      <input
                        placeholder="Password"
                        className="col-5 pl-3 mb-2"
                        defaultValue=" "
                        type="password"
                        name="passwordBefore"
                        onChange={handleCredentialsChange}
                        required
                      />
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <strong className="text-bold">New Password</strong>
                      </div>
                      <input
                        placeholder="Password"
                        className="col-5 pl-3 mb-2 "
                        type="password"
                        name="password1"
                        onChange={handleCredentialsChange}
                        required
                      />
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <strong className="text-bold">Re-enter Password</strong>
                      </div>
                      <input
                        placeholder="Password"
                        className="col-5 pl-3 mb-2"
                        type="password"
                        name="password2"
                        onChange={handleCredentialsChange}
                        required
                      />
                    </div>
                    <div className="row pl-3  justify-content-end">
                      {" "}
                      <div className="col-2">
                        {" "}
                        <button
                          type="submit"
                          className={
                            credentialsButton.status
                              ? "btn btn-success disabled btn-sm "
                              : " btn-sm btn btn-success"
                          }
                          disabled={credentialsButton.status}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <p>Nothing to show :(</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col">
          {" "}
          <div className="btn btn-dark" onClick={props.onHide}>
            Close
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default SettingsPopup;
