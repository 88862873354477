import React  from "react";
import {BrowserRouter,Redirect} from "react-router-dom";
import { Switch, Route} from "react-router-dom";
import  Main  from "./Components /Main";
import {PrivateRoute} from "./reducers/PrivateRoute";
import EntryPoint from "./Components /EntryPoint";


function App(){
  /*
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    fetch('https://api.npms.io/v2/search?q=react')
        .then(response => response.json())
        .then(data => setTotalReactPackages(data.total));

// empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);*/
  return(

    <BrowserRouter>
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/EJohns"/> }/>
      <Route path="/EJohns" component={EntryPoint} />
      <PrivateRoute path="/home" render={props => <Main {...props}/>} />
      {/* <PrivateRoute path="/home" render={props => <HomePage {...props} />}/>*/}
    </Switch>
    </BrowserRouter>

  
  );


}
export default App;