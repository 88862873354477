

import React, { useState } from "react";
import sclogo from "../assets/compuLearnSource/EjohnsBadgeWhite.png";
import "../css/login.css";
import { Switch, Route } from "react-router-dom";
import AccountRecovery from "../Pages/AccountRecovery";
import Login from "../Pages/Login";
import SetEmail from "../Pages/SetEmail";

import { useSpring, animated } from "react-spring";
import {useSelector} from "react-redux";

function EntryPoint() {
  const loggedIn = useSelector((state)=>state.authenticationReducer.loggedIn)

  let [time, setTime] = useState({
    time: "",
  });

  React.useEffect(() => {
    let ctime = new Date().getHours();
    if (+ctime >= 0 && +ctime <= 11) {
      setTime(() => ({ time: "MORNING" }));
    } else if (+ctime >= 12 && +ctime <= 17) {
      setTime(() => ({ time: "AFTERNOON" }));
    } else {
      setTime(() => ({ time: "EVENING" }));
    }
  }, []);

  const logo = useSpring({
    opacity: 1,
    marginTop: 0,
    from: { opacity: 1, marginTop: 200 },
    config: {
      duration: 1000,
    },
    delay: 3000,
  });

  const loginBody = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 500 },
    delay: 4000,
  });

  const greeting = useSpring({
    opacity: 0,
    from: { opacity: 1 },
    config: {
      duration: 1000,
    },
    delay: 2000,
  });

  React.useEffect(() => {
    if (logo || loginBody || greeting) {
      document.body.classList.add("animation");
    } else {
      document.body.classList.remove("animation");
    }
  }, [logo, loginBody, greeting]);

  return (
    <>
      <div className={"login_body container-fluid  d-flex align-items-center"}>
        <div className="container-fluid">
          <animated.div
            style={logo}
            className="row justify-content-center  mb-3 "
          >
            <img src={sclogo} width="160" alt="logo" />
          </animated.div>

          <animated.div
            style={{
              ...greeting,
              position: "fixed",
              left: "25vw",
              width: "50vw",
              color: "white",
              fontWeight: "bolder",
              fontSize: "1.5em",
              fontFamily: "noto sans",
            }}
            className="row justify-content-center m-auto"
          >
            {loggedIn?(<p>Continue SignUp</p>):(
            <p>{"GOOD " + time.time}</p>)}
          </animated.div>
          <div className={"row    "}>
            <animated.div
              style={loginBody}
              className={
                "col-10 col-md-6 col-lg-4 m-auto   bg-light rounded shadow  shadow-md entrypoint"
              }
            >
              <Switch>
             
                <Route
                  exact
                  path="/EJohns/AccountRecovery"
                  component={AccountRecovery}
                />
                <Route
                  exact
                  path="/EJohns/SetEmail"
                  component={SetEmail}
                />
                <Route exact path="/EJohns" component={Login} />
              </Switch>
            </animated.div>
          </div>
          <animated.div style={loginBody} className="row mt-1">
            <div className="col">
              <p
                className=" text-center "

              >
                <a href="https://graphiclab.co.zw" style={{ color: "red", fontSize: "small" }}> © 2021 Graphic Lab Information Services</a>
              </p>
            </div>
          </animated.div>
        </div>
      </div>
    </>
  );
}
export default EntryPoint;
