import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../actions/admin.actions";
import { pagesConstants } from "../constants/pages.constants";
import { pagesActions } from "../actions/pages.actions";
import { popupActions } from "../actions/popup.actions";
import Switch from "react-switch";
import { MDBDataTableV5 } from "mdbreact";
import Footer from "../Components /Footer";
import {Link} from "react-router-dom";

// call render users after update service

function PortalUsers() {
  let [currentDate, setCurrentDate] = useState({
    time: 0,
  });

  let message = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.adminReducer.users);
  const userFetched = useSelector((state) => state.adminReducer.fetchedUser);
  const fetcthingUsers = useSelector(
    (state) => state.adminReducer.fetchingUsers
  );
  const resetingPin = useSelector((state) => state.adminReducer.resetingPin);
  const user = useSelector((state) => state.authenticationReducer.user);
  const pinId = useSelector((state) => state.adminReducer.pinId);

  React.useEffect(() => {
    dispatch(pagesActions.updatepage(pagesConstants.PORTAL));

    dispatch(adminActions.getUsers(user.branchId));

    const timer = setInterval(() => {
      setCurrentDate((currentDate) => ({ time: +currentDate.time + 1 }));
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  function handleToggleAccount(userId, enabled) {
    console.log("handler" + userId + enabled);
    dispatch(adminActions.toggleAccount(userId, enabled));
  }

  function renderUsers(users) {
    let datatable = {
      columns: [
        {
          label: "username",
          field: "username",
          sort: "asc",
          //   width: 100,
        },
        {
          label: "Status",
          field: "status",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Attempts",
          field: "attempts",
          sort: "disabled",
          width: 150,
          attributes: {
            "aria-controls": "DataTable",
            "aria-label": "userID",
          },
        },

        {
          label: "Email",
          field: "email",
          sort: "disabled",
          // width: 100,
        },
        {
          label: "Role",
          field: "role",
          sort: "disabled",
          // width: 100,
        },
      ],
      rows: users.map((user) => {
        return {
          ...user,
          role :  user.role===1 ? (
              <label className="p-0 m-0 " style={{ fontSize: "smaller" }}>
                Regular User
              </label>
          ) : (
              <label className="p-0 m-0 " style={{ fontSize: "smaller" }}>
               Administrator
              </label>
          ),
          status: (
            <div className="">
              <Switch
                offColor="#083513"
                onColor="#ABE86C"
                height={20}
                width={48}
                handleDiameter={17}
                className="react-switch"
                uncheckedIcon={false}
                checkedIcon={false}
                checked={user.enabled}
                onChange={() =>
                  handleToggleAccount(user.username, !user.enabled)
                }
              />{" "}
              <br />
              {resetingPin && pinId == user.username ? (
                <label className="p-0 m-0" style={{ fontSize: "smaller" }}>
                  Please Wait{" "}
                </label>
              ) : (
                <label className="p-0 m-0" style={{ fontSize: "smaller" }}>
                  {user.enabled ? "enabled" : "disabled"}
                </label>
              )}
              {/* <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="customSwitches" name={user.username} checked={user.enabled} onChange={()=> handleToggleAccount(user.username,!user.enabled)} />
                        <label className="custom-control-label" for={"customSwitches"}>{user.enabled?"enabled":"disabled"}</label>
                    </div> */}
            </div>
          ),
        };
      }),
    };

    return (
      <>
        <div className="card p-3 pt-0 ">
          <div className="col text-dark m-0 p-0">
            <h5>
              <strong>Portal Users</strong>
            </h5>
          </div>
          <div className="col">
            {" "}
            {message ? (
              <div className={message.type}>{message.message}</div>
            ) : (
              ""
            )}
          </div>
          <MDBDataTableV5
            entriesOptions={[5, 10, 20, 25, 35, 40, 50, 100]}
            btn
            hover
            // autoWidth
            striped
            // bordered
            // scrollX
            responsive
            entries={5}
            pagesAmount={4}
            data={datatable}
            pagingTop
            searchTop
            searchRight
            searchBottom={false}
            fullPagination
          />
          ;
        </div>
      </>
    );
  }

  return (
    <>
      <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
        <div className={"block-header "}>
          <div className={"row clearfix  align-items-end pl-3 "}>
            <div className="col col-md-6 col-sm-12">
              <h2> Manage Users</h2>
            </div>
            <div
              className={
                "pl-4 col d-flex  text-muted timer  justify-content-end align-items-center"
              }
            >
              <i
                className={
                  +currentDate.time < 10
                    ? "fa fa-refresh fa-md btn text-success"
                    : "fa fa-refresh fa-md btn text-danger"
                }
                onClick={() => {
                  dispatch(adminActions.getUsers());
                  setCurrentDate((currentDate) => ({ time: 0 }));
                }}
              ></i>

              {currentDate.time < 1 ? " Just Updated" : ""}
              {currentDate.time == 1 ? " Updated a minute ago" : ""}
              {currentDate.time > 1
                ? " Last Updated " + currentDate.time + " minutes ago"
                : ""}
              {currentDate.time >= 10
                ? " You need to refresh page to get the latest Information"
                : ""}
            </div>
          </div>
        </div>
        { message ? (
            <div className={message.type + " my-1"}>
              {" "}
              <div className="popup"> {message.message}</div>
            </div>) : ("")
        }
        {/*Breadcrumb*/}
        <nav aria-label="breadcrumb" className="main-breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/home">
              Home
            </Link></li>
            <li className="breadcrumb-item active" aria-current="page">Users</li>
          </ol>
        </nav>
        {/*Breadcrumb*/}

        <div className={"container-fluid"}>
          <div className={"row "}>
            <div className={"col-lg-12"}>
              {fetcthingUsers ? (
                <div className="spinner-grow text-dark" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                ""
              )}
              {userFetched ? renderUsers(users) : ""}
            </div>
          </div>
        </div>
       <Footer/>

      </div>
    </>
  );
}
export default PortalUsers;
