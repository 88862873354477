import React from "react";

import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

import {pagesConstants} from "../constants/pages.constants";

function Sidebar() {
    let role = JSON.parse(localStorage.getItem("user")).roles;

    let adminMode = role.includes("ROLE_ADMIN")||role.includes("ROLE_SUPER_ADMIN");
    let studentMode =role.includes("ROLE_STUDENT");
    let teacherMode = role.includes("ROLE_TEACHER");
    let hodMode = role.includes("ROLE_HOD");
    let classTeacherMode= role.includes("ROLE_CLASSTEACHER");

    // public static final int  = 0;
    // public static final int  = 1;
    // public static final int ROLE_HOD = 2;
    // public static final int ROLE_HEAD = 3;
    // public static final int ROLE_ADMIN = 4;
    // public static final int  = 5;

    const activepage = useSelector((state) => state.pagesReducer.active_page);

    return (
        <React.Fragment>
            <div id={"left-sidebar"} className={"sidebar shadow shadow-sm"}>
                <div className={"sidebar_list"}>
                    <div className={"tab-content"} id={"main-menu"}>
                        <div className={"tab-pane active"} id={"Home-icon"}>
                            <nav className={"sidebar-nav sidebar-scroll"}>
                                <ul className={"metismenu"}>
                                    <br/>
                                    <b/>

                                    <div className="d-flex">
                                        <i
                                            style={{color: "#ffc107"}}
                                            className=" mx-2 fas fa-square-full fa-1x"
                                        ></i>

                                        <p
                                            className="p-0 m-0 text-muted"
                                            style={{fontSize: "smaller", fontWeight: "bolder"}}
                                        >
                                            ACADEMICS
                                        </p>
                                    </div>
                                    {/*PROFILE*/}
                                    <div
                                        className={
                                            activepage === pagesConstants.PROFILE
                                                ? " bg-muted   " + activepage
                                                : ""
                                        }
                                    >
                                        <Link to="/home">
                                            <li>
                                                {activepage === pagesConstants.PROFILE ? (
                                                    <i className=" mx-2 fa fa-home fa-1x"></i>
                                                ) : (
                                                    ""
                                                )}
                                                <span>Home</span>
                                            </li>
                                        </Link>
                                    </div>

                                    {/*FULL CALENDAR*/}
                                    <div
                                        className={
                                            activepage === pagesConstants.CALENDAR
                                                ? " bg-muted   " + activepage
                                                : ""
                                        }
                                    >
                                        <Link to="/home/Calendar">
                                            <li>
                                                {activepage === pagesConstants.CALENDAR ? (
                                                    <i className="mx-2 fas fa-calendar-alt"></i>
                                                ) : (
                                                    ""
                                                )}
                                                <span>Calendar</span>
                                            </li>
                                        </Link>
                                    </div>


                                    {!studentMode?(<>


                                        {/*SUBJECT MARKS*/}
                                        <div
                                            className={
                                                activepage === pagesConstants.SCHEMA
                                                    ? " bg-muted   " + activepage
                                                    : ""
                                            }
                                        >
                                            <Link to="/home/MarkingSchema">
                                                <li>
                                                    {activepage === pagesConstants.SCHEMA ? (
                                                        <i className=" mx-2 fa fa-archive fa-1x"></i>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span>Marking Schemas</span>
                                                </li>
                                            </Link>
                                        </div>
                                        {classTeacherMode?(<>
                                        {/*CLASS REGISTRATION*/}
                                        <div
                                            className={
                                                activepage === pagesConstants.REGISTRATION
                                                    ? " bg-muted   " + activepage
                                                    : ""
                                            }
                                        >
                                            <Link to="/home/Registration">
                                                <li>
                                                    {activepage === pagesConstants.REGISTRATION ? (
                                                        <i className=" mx-2 fa fa-archive fa-1x"></i>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span>Class Registrations</span>
                                                </li>
                                            </Link>
                                        </div>
                                        </>):""}





                                        {/*SUBJECT MARKS*/}

                                        <div
                                            className={
                                                activepage === pagesConstants.HOME
                                                    ? " bg-muted   " + activepage
                                                    : ""
                                            }
                                        >
                                            <Link to="/home/Marks">
                                                <li>
                                                    {activepage === pagesConstants.HOME ? (
                                                        <i className=" mx-2 fa fa-book fa-1x"></i>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span>My Subjects</span>
                                                </li>
                                            </Link>
                                        </div>
                                        {adminMode?(<>
                                        {/*SPORT MARKS*/}
                                        <div
                                            className={
                                                activepage === pagesConstants.SPORTS
                                                    ? " bg-muted   " + activepage
                                                    : ""
                                            }
                                        >
                                            <Link to="/home/SportMarks">
                                                <li>
                                                    {activepage === pagesConstants.SPORTS ? (
                                                        <i className=" mx-2 fa fa-home fa-1x"></i>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span>Sports</span>
                                                </li>
                                            </Link>
                                        </div>

                                        {/*CLUB MARKS*/}
                                        <div
                                            className={
                                                activepage === pagesConstants.CLUBS
                                                    ? " bg-muted   " + activepage
                                                    : ""
                                            }
                                        >
                                            <Link to="/home/ClubMarks">
                                                <li>
                                                    {activepage === pagesConstants.CLUBS ? (
                                                        <i className=" mx-2 fa fa-home fa-1x"></i>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span>Clubs</span>
                                                </li>
                                            </Link>
                                        </div>
                                            </>)
                                            :""}

                                        {classTeacherMode?(<>
                                        {/*CLASS TEACHER COMMENTS*/}
                                        <div
                                            className={
                                                activepage === pagesConstants.STUDENTSCOMMENTS
                                                    ? " bg-muted   " + activepage
                                                    : ""
                                            }
                                        >
                                            <Link to="/home/StudentBehaviorAndAttendance">
                                                <li>
                                                    {activepage === pagesConstants.STUDENTSCOMMENTS ? (
                                                        <i className=" mx-2 fa fa-home fa-1x"></i>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span>Student Behavior And Attendance </span>
                                                </li>
                                            </Link>
                                        </div>
                                            </>)
                                            :""}

                                        {hodMode||adminMode?(<>
                                        {/*DEPARTMENT STATISTICS*/}
                                        <div
                                            className={
                                                activepage === pagesConstants.DEPARTMENTS
                                                    ? " bg-muted   " + activepage
                                                    : ""
                                            }
                                        >
                                            <Link to="/home/Departments">
                                                <li>
                                                    {activepage === pagesConstants.DEPARTMENTS ? (
                                                        <i className=" mx-2 fa fa-home fa-1x"></i>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span>Departments</span>
                                                </li>
                                            </Link>
                                        </div>
                                                {/*REGISTER STUDENTS*/}
                                                <div
                                                    className={
                                                        activepage === pagesConstants.REGISTER
                                                            ? " bg-muted   " + activepage
                                                            : ""
                                                    }
                                                >
                                                    <Link to="/home/registerStudents">
                                                        <li>
                                                            {activepage === pagesConstants.REGISTER ? (
                                                                <i className=" mx-2 fa fa-users fa-1x"></i>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <span>Register Students</span>
                                                        </li>
                                                    </Link>
                                                </div>






                                    {/*HEADING*/}
                                    <div className="d-flex">
                                        <i
                                            style={{color: "#ffc107"}}
                                            className=" mx-2 fas fa-square-full fa-1x"
                                        ></i>

                                        <p
                                            className="p-0 m-0 text-muted"
                                            style={{fontSize: "smaller", fontWeight: "bolder"}}
                                        >
                                            Students
                                        </p>
                                    </div>


                                        {/*STUDENT CONTACTS*/}
                                        <div
                                            className={
                                                activepage === pagesConstants.CONTACTS
                                                    ? " bg-muted   " + activepage
                                                    : ""
                                            }
                                        >
                                            <Link to="/home/Contacts">
                                                <li>
                                                    {activepage === pagesConstants.CONTACTS ? (
                                                        <i className=" mx-2 fa fa-address-book fa-1x"></i>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span>Student Contacts</span>
                                                </li>
                                            </Link>
                                        </div>



                                        {/*STUDENT MEDICAL INFORMATION*/}
                                        <div
                                            className={
                                                activepage === pagesConstants.MEDICALS
                                                    ? " bg-muted   " + activepage
                                                    : ""
                                            }
                                        >
                                            <Link to="/home/Medicals">
                                                <li>
                                                    {activepage === pagesConstants.MEDICALS ? (
                                                        <i className=" mx-2 fas fa-clinic-medical fa-1x"></i>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <span>Student Medical Information</span>
                                                </li>
                                            </Link>
                                        </div>




                                    {/*HEADING*/}
                                    <div className="d-flex">
                                        <i
                                            style={{color: "#ffc107"}}
                                            className=" mx-2 fas fa-square-full fa-1x"
                                        ></i>

                                        <p
                                            className="p-0 m-0 text-muted"
                                            style={{fontSize: "smaller", fontWeight: "bolder"}}
                                        >
                                            Management
                                        </p>
                                    </div>



                                    {/*STUDENT ARREARS*/}
                                    <div
                                        className={
                                            activepage === pagesConstants.ARREARS
                                                ? " bg-muted   " + activepage
                                                : ""
                                        }
                                    >
                                        <Link to="/home/StudentArrears">
                                            <li>
                                                {activepage === pagesConstants.ARREARS ? (
                                                    <i className=" mx-2 fas fa-coins fa-1x"></i>
                                                ) : (
                                                    ""
                                                )}
                                                <span>Students Arrears</span>
                                            </li>
                                        </Link>
                                    </div>
                                            </>)
                                            :""}



                                    {adminMode ? (
                                        <>
                                            {/*HEADING*/}
                                            <div className="d-flex">
                                                <i
                                                    style={{color: "#ffc107"}}
                                                    className=" mx-2 fas fa-square-full fa-1x"
                                                ></i>

                                                <p
                                                    className="p-0 m-0 text-muted"
                                                    style={{fontSize: "smaller", fontWeight: "bolder"}}
                                                >
                                                    ADMINISTRATOR SETTINGS
                                                </p>
                                            </div>

                                            {/*PORTAL USERS*/}
                                            <div
                                                className={
                                                    activepage === pagesConstants.PORTAL
                                                        ? " bg-muted   " + activepage
                                                        : ""
                                                }
                                            >
                                                <Link to="/home/admin/PortalUsers">
                                                    <li>
                                                        {activepage === pagesConstants.PORTAL ? (
                                                            <i className=" mx-2 fa fa-users fa-1x"></i>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span>Portal Users</span>
                                                    </li>
                                                </Link>
                                            </div>

                                            {/*MANAGE NOTIFICATIONS*/}
                                            <div
                                                className={
                                                    activepage === pagesConstants.MANAGENOTIFICATIONS
                                                        ? " bg-muted   " + activepage
                                                        : ""
                                                }
                                            >
                                                <Link to="/home/ManageNotifications">
                                                    <li>
                                                        {activepage === pagesConstants.MANAGENOTIFICATIONS ? (
                                                            <i className=" mx-2 fa fa-home fa-1x"></i>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span>Manage Notifications</span>
                                                    </li>
                                                </Link>
                                            </div>

                                            {/*MANAGE CALENDAR*/}
                                            <div
                                                className={
                                                    activepage === pagesConstants.MANAGECALENDAR
                                                        ? " bg-muted   " + activepage
                                                        : ""
                                                }
                                            >
                                                <Link to="/home/ManageCalendar">
                                                    <li>
                                                        {activepage === pagesConstants.MANAGECALENDAR ? (
                                                            <i className="mx-2 fas fa-calendar-alt"></i>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span>Manage Calendar</span>
                                                    </li>
                                                </Link>
                                            </div>

                                            {/*MANAGE TEACHERS*/}
                                            <div
                                                className={
                                                    activepage === pagesConstants.TEACHERS
                                                        ? " bg-muted   " + activepage
                                                        : ""
                                                }
                                            >
                                                <Link to="/home/ManageTeachers">
                                                    <li>
                                                        {activepage === pagesConstants.TEACHERS ? (
                                                            <i className=" mx-2 fa fa-home fa-1x"></i>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span>Manage Teachers</span>
                                                    </li>
                                                </Link>
                                            </div>

                                            {/*MANAGE STUDENTS*/}
                                            <div
                                                className={
                                                    activepage === pagesConstants.SUPPORT
                                                        ? " bg-muted   " + activepage
                                                        : ""
                                                }
                                            >
                                                <Link to="/home/ManageStudents">
                                                    <li>
                                                        {activepage === pagesConstants.SUPPORT ? (
                                                            <i className=" mx-2 fa fa-home fa-1x"></i>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span>Manage Students</span>
                                                    </li>
                                                </Link>
                                            </div>

                                            {/*ADD PORTAL USER
                                            <div
                                                className={
                                                    activepage === pagesConstants.ADDPORTALUSER
                                                        ? " bg-muted   " + activepage
                                                        : ""
                                                }
                                            >
                                                <Link to="/home/admin/addUser">
                                                    <li>
                                                        {activepage === pagesConstants.ADDPORTALUSER ? (
                                                            <i className=" mx-2 fa fa-user fa-1x"></i>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <span>Add Portal User</span>
                                                    </li>
                                                </Link>
                                            </div>*/}
                                        </>
                                    ) : (
                                        ""
                                    )}

                                    </>):""}

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Sidebar;
