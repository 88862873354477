
export const pagesActions = {
    updatepage
};

function updatepage(info) {
    return { type:info , active_page:info };
}



