import {
    apiConstants
} from "../constants/api.constants";

import {
    authHeader
} from "../shared/auth-header";
import {history} from "../shared/history";
import {userConstants} from "../constants/user.constants";

export const teacherService = {
    genReports,
    validateId,
    validateTeacherId,
    getTeacher,
    getSubjectMarks,
    getClubMarks,
    getSportMarks,
    submitMarks,
    uploadStudents,
    submitMark,
    submitSportMark,
    submitClubMark,
    getOptions,
    getClassOptions,
    getStudentArrears,
    getClassArrears,
    saveStudent,
    saveTeacher,
    editTeacher,
    updateStudent,
    getStudents,
    getTeachers,
    getClassStudents,
    getUnregisteredStudents,
    getClassRegistration,
    getClassRegistrationbyTic,
    getRegisteredStudents,
    RegisterStudents,
    CreateArrear,
    ClearStudent,
    getDepartmentStats,
    clearDepartment,
    getStudentContacts,
    CreateContact,
    ClearContact,
    getStudentMedicals,
    CreateMedicalCondition,
    getStudentComments,
    submitClassTeacherComments,
    getCalendar,
    RemoveCalendarEvent,
    UpdateCalendar,
    getNotifications,
    UpdateNotifications,
    RemoveNotification,
    getProfile,
    getMiniProfile,
    getTeacherMiniProfile,
    retrievePdfResults,
    downloadClearances,
    getPDFClassLists,
    getPDFCalendar,
    removeStudentSubject,
    removeStudentSport,
    removeStudentClub,
    AddSubject,
    AddSport,
    AddClub,
    downloadMarkTemplate


}

function AddSport(studentId,sport) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/addStudentSport/${studentId}/${sport}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function AddClub(studentId,club) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/addStudentClub/${studentId}/${club}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function AddSubject(studentId,subject) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/addStudentSubject/${studentId}/${subject}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function removeStudentSport(studentId,sport) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/removeStudentSport/${studentId}/${sport}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function removeStudentClub(studentId,club) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/removeStudentClub/${studentId}/${club}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function removeStudentSubject(studentId,subject) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/removeStudentSubject/${studentId}/${subject}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}



function getPDFCalendar(getPDFCalendar) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },


    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Calendar`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getPDFClassLists(list) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(list)

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/ClassLists`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function downloadClearances() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/StudentPDFClearances`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getTeacherMiniProfile(ID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/MiniProfile/${ID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getMiniProfile(ID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/MiniProfile/${ID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function retrievePdfResults(ID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/StudentPDFResults/${ID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getProfile() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Profile`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function RemoveNotification(notificationId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/DeleteNotification/${notificationId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function UpdateNotifications(notificationbody) {
    const requestOptions = {
        method: 'POST',
        headers: {

            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(notificationbody)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/SaveNotification`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getNotifications() {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/notifications`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function UpdateCalendar(eventbody) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(eventbody)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/SaveCalender`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function RemoveCalendarEvent(eventId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/DeleteCalender/${eventId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getCalendar() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/calender`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function CreateMedicalCondition(condition) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(condition)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/MedicalsSave`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function CreateContact(contact) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(contact)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ContactSave`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function ClearContact(contact) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ContactsClear/${contact}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}



function getStudentMedicals() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Student/Medicals`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getStudentContacts() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };

    return fetch(`${apiConstants.API_BASE_URL}/api/Student/Contacts`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function clearDepartment(dptId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/ClearDepartment/${dptId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function genReports() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/genReports`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function ClearStudent(clrId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ToggleStudentArrears/${clrId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function validateTeacherId(ID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/validateTeacher/${ID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function validateId(ID) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/validateStudent/${ID}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getStudentComments(user) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/StudentComments/${user}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getSportMarks() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/SportMarks`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getClubMarks() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ClubMarks`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getDepartmentStats(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/DepartmentStat/${tic}`, requestOptions)
    //return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/DepartmentStat/all`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getStudentArrears(stud) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/getStudentArrears/${stud}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getClassArrears(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/getClassArrears/${tic}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getRegisteredStudents(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/RegisteredStudents/${tic}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getClassRegistrationbyTic() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        }
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ClassRegistrationByTic`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getClassRegistration(cls) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        }
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/ClassRegistration/${cls}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getUnregisteredStudents(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/UnRegisteredStudents/${tic}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getClassStudents(classId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/getClass/${classId}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getTeachers() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teachers/get`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getStudents() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/get`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}


function getOptions() {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/StudentAddOptions`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

    function CreateArrear(arrear) {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                ...authHeader()
            },
            body: JSON.stringify(arrear)
        };
        return fetch(`${apiConstants.API_BASE_URL}/api/Student/saveStudentArrears`, requestOptions)
            .then(handleResponse)
            .then(options => {
                return options;
            });
    }
function RegisterStudents(students) {
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(students)
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/Register`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}

function getClassOptions(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/StudentAddOptions/${tic}`, requestOptions)
        .then(handleResponse)
        .then(options => {
            return options;
        });
}
function getTeacher(tic) {
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        // body: JSON.stringify({
        //     "id": userid
        // })
    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/`+tic, requestOptions)
        .then(handleResponse)
        .then(tic => {
            return tic;
        });
}

function downloadMarkTemplate(Subject ,SubjectClass){
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/SubjectMarkTemplates/`+ Subject+"/"+SubjectClass, requestOptions)
        .then(handleResponse)
        .then(marks => {
            return marks;
        });
}
function getSubjectMarks(Subject ,SubjectClass){
    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/SubjectMarks/`+ Subject+"/"+SubjectClass, requestOptions)
        .then(handleResponse)
        .then(marks => {
            return marks;
        }); 
}


function submitClassTeacherComments(comment){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(comment)

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadStudentComments`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function submitMarks(file,Subject,SubjectClass){
    const requestOptions = {
        method: 'POST',
        headers: {

            ...authHeader()
        },
        body:file

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadBatchMarks/`+ Subject+"/"+SubjectClass, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function uploadStudents(file){
    const requestOptions = {
        method: 'POST',
        headers: {

            ...authHeader()
        },
        body:file

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadBatchStudents`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function updateStudent(student,Id){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body:JSON.stringify(student)

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/update/${Id}`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function editTeacher(teacher){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body:JSON.stringify(teacher)

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/editTeacher`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function saveTeacher(teacher){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body:JSON.stringify(teacher)

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/save`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function saveStudent(student){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body:JSON.stringify(student)

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Student/save`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}
function submitMark(mark){
    const requestOptions = {
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',

            ...authHeader()
        },
        body:   JSON.stringify({...mark})

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadMarks`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function submitSportMark(mark){
    const requestOptions = {
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',

            ...authHeader()
        },
        body:   JSON.stringify({...mark})

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadSportMark`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function submitClubMark(mark){
    const requestOptions = {
        method: 'POST',
        headers: {

            'Content-Type': 'application/json',

            ...authHeader()
        },
        body:   JSON.stringify({...mark})

    };
    return fetch(`${apiConstants.API_BASE_URL}/api/Teacher/uploadClubMark`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}




function handleResponse(response) {
    
    return response.text().then(text => {
   
        const data = text && JSON.parse(text);
         if (!response.ok){
           // const error = (data && data.Message) || response.statusBody;
             if (response.status === 401) {
                 // auto logout if 401 response returned from api
                 logout();

             }
            return Promise.reject(data);
        }
        return data;
    })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    history.push('/');
    window.location.reload(true);
    return {
        type: userConstants.USER_LOGOUT
    };
}

