
import "../css/dash.css";


import React, {useState} from "react";
import { useDispatch,useSelector } from "react-redux";

import { pagesConstants } from "../constants/pages.constants";
import { teacherActions } from "../actions/teacher.actions";
import { pagesActions } from "../actions/pages.actions";

import {alertActions} from '../actions';
import {Collapse} from "reactstrap";
import Footer from "../Components /Footer";
import {Link} from "react-router-dom";




function  Departments() {



  const dispatch = useDispatch();
  const message = useSelector((state) => state.alert);
  const departments = useSelector((state) => state.teacherReducer.Departments);
  const netError = useSelector((state) => state.teacherReducer.netError);
  const user = useSelector((state)=>state.authenticationReducer.user.userId)

    React.useEffect(() => {
        if(!departments && !netError){
            dispatch(teacherActions.getDepartmentStats(user))
        }
        dispatch(pagesActions.updatepage(pagesConstants.DEPARTMENTS));
        return(()=>{
            dispatch(teacherActions.resetNetError())
        })
    },[])

    const [classStats,setClassStats] = useState({

    });
    const [currentClass,setCurrentClass] = useState({
        class:"",
        classDetail:""
    });


    function changeStatsView(e) {
        let details = e.target.getAttribute("id").split("|");
        let dptName = details[0];
        let subName = details[1];
        departments.departments.map(dpt=>{
            if(dpt.name===dptName){
                dpt.subjectStats.map(sub=>{
                    if(sub.name===subName){
                        setClassStats((classStat) => ({classStats:sub.classStats,name:subName,dpt:dpt.name}))
                    }
                })
            }

        })

    }


    function toggleSub(e) {

        let currentCls = e.target.getAttribute("id")
        let details = currentCls.split("|");
        let dptName = details[0];
        let subName = details[1];
        let className = details[1];
        console.log(classStats.classStats)
        if(currentCls!==currentClass.class){
            classStats.classStats.map(sub=>{
                        if(sub.name===subName){
                            setCurrentClass(() => ({classDetail:sub,class:currentCls}))

                        }
                    })
        }else{
            setCurrentClass(() => ({class:""}));
        }

        console.log(currentClass.class)
    }

    function clearDepartment(e) {
        let dptName = e.target.getAttribute("id")
        dispatch(alertActions.success("Working Please Wait..."))
        dispatch(teacherActions.clearDepartment(dptName,user));

    }

    function genReports() {
        dispatch(teacherActions.genReports());
    }

    let role = JSON.parse(localStorage.getItem("user")).roles.toString();
    let adminMode = role=== "ROLE_SUPER_ADMIN";
    let studentMode =role ==="ROLE_STUDENT";
    let teacherMode = role ==="ROLE_TEACHER";
    let hodMode = role === "ROLE_HOD";
    let classTeacherMode= role === "ROLE_CLASSTEACHER";

    return (
            <>
                <div id="main-content" className="px-2 px-lg-5 px-md-3 px-sm-2  py-1">
                  <div className={"block-header "}>

                      <div className={"row clearfix  align-items-end  "}>
                          <div className={"col text-bold  "}>
                              <strong><h2>School Departments</h2></strong>
                          </div>
                          {departments&&departments.departments&&departments.allStatus&&adminMode?(
                              <button onClick={genReports}  className="col-3 btn btn-small btn-rounded btn-info btn-outline">
                                  <small>Generate Student Reports</small>
                              </button>):""}
                      </div>
                  </div>
                  { message ? (
                      <div className={message.type + " my-1"}>
                          {" "}
                          <div className="popup"> {message.message}</div>
                      </div>) : ("")
                  }
                  {/*Breadcrumb*/}
                  <nav aria-label="breadcrumb" className="main-breadcrumb">
                      <ol className="breadcrumb">
                          <li className="breadcrumb-item"><Link to="/home">
                              Home
                          </Link></li>
                          <li className="breadcrumb-item active" aria-current="page">Departments</li>
                      </ol>
                  </nav>
                  {/*Breadcrumb*/}



                  <div className="row mt-2 ">
                      <div className="col">
                          <div className="container-fluid ">
                               {departments&&departments.departments?departments.departments.map(dpt=> {
                                  return(<>
                                           <div className="row  border rounded bbg-white">

                                              <div className=" container-fluid">

                                                  <div className="row p-2 pl-3 pt-4 ">
                                                      <div className="col"><div className={"h5"}><strong>{dpt.name}</strong></div></div>
                                                      {!dpt.status?(
                                                      <button onClick={clearDepartment} id={dpt.name} className="col-2 btn btn-small btn-rounded btn-success btn-outline">
                                                          <small id={dpt.name}>Submit Department</small>
                                                      </button>):""}
                                                  </div>
                                                  <div className="row pb-5 pt-0 mt-0">
                                                      <div className="col-3">
                                                          <div className="container-fluid">
                                                              {
                                                                  dpt.subjectStats.map(subject=>{

                                                                      return(
                                                                          <div className={(classStats.name===subject.name)?"active row dptMenu":"row dptMenu "  } onClick={changeStatsView} id={dpt.name+"|"+subject.name}>{subject.name}</div>
                                                                      )


                                                                  })

                                                              }
                                                          </div>
                                                      </div>
                                                      <div className="col">

                                                          { classStats&&classStats.classStats&&classStats.dpt===dpt.name?classStats.classStats.map( sub=>{ return (<>

                                                                      <div className={(classStats.name+"|"+sub.name===currentClass.class)?"active card shadow subjectRow":"card shadow subjectRow"}  id={classStats.name+"|"+sub.name} style={{cursor:"pointer"}}>
                                                                          <div className={(classStats.name+"|"+sub.name===currentClass.class)?"active card-header d-flex bg-light subjectRow":"card-header d-flex bg-light subjectRow"} onClick={toggleSub} key={classStats.name+sub.name} id={classStats.name+"|"+sub.name}>

                                                                              <div className="col" id={classStats.name+"|"+sub.name}><strong>{sub.name}</strong></div>
                                                                              <div className="col" id={classStats.name+"|"+sub.name}>{sub.totalStudents} <div className="badge-dark badge-sm rounded text-center">candidates</div></div>
                                                                              <div className="col d-none d-sm-block " id={classStats.name+"|"+sub.name}>{+sub.passrate.toFixed(2)} <div className="badge-info badge-sm rounded text-center">passrate</div></div>
                                                                              <div className="col d-none d-sm-block" id={classStats.name+"|"+sub.name}>{+sub.average.toFixed(2)}<div className="badge-info badge-sm rounded text-center">average</div>< /div>
                                                                              <div className="col" id={classStats.name+"|"+sub.name}>{+sub.progress.toFixed(2)}<div className="badge-success badge-sm rounded text-center">marked</div></div>

                                                                              <div  className="zimra-menu col-2" id={classStats.name+"|"+sub.name}>
                                                                                  <i className={"fa fa-bars fa-md  m-0 p-0"} id={classStats.name+"|"+sub.name}></i>
                                                                              </div>

                                                                          </div>
                                                                          <Collapse isOpen={classStats.name+"|"+sub.name===currentClass.class}>
                                                                              <div className="card-body bg-light">
                                                                                  {currentClass&&currentClass.classDetail?
                                                                                      (<>
                                                                                          <div className="container-fluid ">
                                                                                              <div className="row">
                                                                                                  <div className="col">Teacher : {currentClass.classDetail.tic}</div>
                                                                                              </div>
                                                                                              <div className="row">
                                                                                                  <div className="col">Total Students : {currentClass.classDetail.totalStudents}</div>
                                                                                              </div>
                                                                                              <div className="row">
                                                                                                  <div className="col">Passrate : {+currentClass.classDetail.passrate.toFixed(2)}</div>
                                                                                              </div>
                                                                                              <div className="row">
                                                                                                  <div className="col">Average : {+currentClass.classDetail.average.toFixed(2)}</div>
                                                                                              </div>
                                                                                              <div className="row">
                                                                                                  <div className="col">{+currentClass.classDetail.progress.toFixed(2)} % marked </div>
                                                                                              </div>
                                                                                              <br/>
                                                                                              <div className="row">
                                                                                                  <div className="container-fluid bg-white rounded border text-dark">
                                                                                                      <div className="row">
                                                                                                          <div className="col">
                                                                                                              <strong className="text-black">HIGHEST STUDENT</strong>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                      <div className="row">
                                                                                                          <div className="col">
                                                                                                              <strong>{currentClass.classDetail.highestStudent.name}</strong>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                      <br/>
                                                                                                      <div className="row">
                                                                                                          <div className="col">ID : {currentClass.classDetail.highestStudent.studentID}</div>
                                                                                                      </div>
                                                                                                      <div className="row">
                                                                                                          <div className="col">Name : {currentClass.classDetail.highestStudent.name}</div>
                                                                                                      </div>
                                                                                                      <div className="row">
                                                                                                          <div className="col">Mark : {currentClass.classDetail.highestStudent.mark}</div>
                                                                                                      </div>
                                                                                                      <div className="row">
                                                                                                          <div className="col">Gender : {currentClass.classDetail.highestStudent.gender}</div>
                                                                                                      </div>

                                                                                                  </div>
                                                                                              </div>
                                                                                              <br/>
                                                                                              <div className="row">
                                                                                                  <div className="container-fluid bg-white rounded border text-dark">
                                                                                                      <div className="row">
                                                                                                          <div className="col">
                                                                                                              <strong className="text-black">LOWEST STUDENT</strong>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                      <div className="row">
                                                                                                          <div className="col">
                                                                                                              <strong>{currentClass.classDetail.lowestStudent.name}</strong>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                      <br/>
                                                                                                      <div className="row">
                                                                                                          <div className="col">ID : {currentClass.classDetail.lowestStudent.studentID}</div>
                                                                                                      </div>
                                                                                                      <div className="row">
                                                                                                          <div className="col">Name : {currentClass.classDetail.lowestStudent.name}</div>
                                                                                                      </div>
                                                                                                      <div className="row">
                                                                                                          <div className="col">Mark : {currentClass.classDetail.lowestStudent.mark}</div>
                                                                                                      </div>
                                                                                                      <div className="row">
                                                                                                          <div className="col">Gender : {currentClass.classDetail.lowestStudent.gender}</div>
                                                                                                      </div>

                                                                                                  </div>
                                                                                              </div>

                                                                                          </div>
                                                                                      </>)

                                                                                      : ":( No Stats Found for Subject"}
                                                                              </div>


                                                                          </Collapse>

                                                                      </div>
                                                                  </>
                                                              )
                                                              })
                                                              : ""}


                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      <br/><br/>
                                      </>)
                                  }):""
                               }
                          </div>
                      </div>
                  </div>

                     <Footer/>

                  </div>



            </>);

}

export default Departments;
