import {
    apiConstants
} from "../constants/api.constants";
import {
    userConstants
} from "../constants/user.constants";
import {
    authHeader
} from "../shared/auth-header";
//import {authHeader} from "../shared/auth-header";
import {
    history
} from "../shared/history";

export const adminService = {

    getUsers,
    getUser,
    resetPassword,
    toggleAccount,
    newUser,
    editAccount

};

function getUsers(branch) {

    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };


    return fetch(`${apiConstants.API_BASE_URL}/api/admin/getUsers`, requestOptions)
        .then(handleResponse)
        .then(users => {

            return users;
        });
}

function resetPassword(userid) {

    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify({
            "id": userid
        })
    };


    return fetch(`${apiConstants.API_BASE_URL}/api/admin/resetPassword`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function toggleAccount(userid, enabled) {
    console.log("service" + userid + enabled)
    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify({
            "username": userid,
            enabled
        })
    };


    return fetch(`${apiConstants.API_BASE_URL}/api/admin/toggleAccount`, requestOptions)
        .then(handleResponse)
        .then(user => {

            return user;
        });
}

function getUser(userid) {

    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify({
            "id": userid
        })
    };


    return fetch(`${apiConstants.API_BASE_URL}/api/admin/getUser`, requestOptions)
        .then(handleResponse)
        .then(user => {

            return user;
        });
}

function newUser(user) {

    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(user)
    };


    return fetch(`${apiConstants.API_BASE_URL}/api/admin/newUser`, requestOptions)
        .then(handleResponse)
        .then(user => {

            return user;
        });
}


function editAccount(accountdetails) {


    const requestOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            ...authHeader()
        },
        body: JSON.stringify(accountdetails)
    };


    return fetch(`${apiConstants.API_BASE_URL}/api/admin/editUser`, requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}



function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);


        if (!response.ok) {

            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    history.push('/');
    window.location.reload(true);
    return {
        type: userConstants.USER_LOGOUT
    };
}