import React, { useState } from "react";

import { Link } from "react-router-dom";
import "../css/login.css";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../actions/user.actions";

function AccountRecovery() {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: "",
  });
  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    setUser((user) => ({ ...user, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    // setSubmitted(true);
    //console.log(user)
    if (user.email) {
      e.preventDefault();

      dispatch(
        userActions.accountRecovery({
          username: "",
          email: user.email,
          password: "",
        })
      );
    } else {
      alert(
        "oops, looks like you did not fill in all the required fields try again"
      );
    }
  }
  const loading = useSelector(
    (state) => state.authenticationReducer.settingEmail
  );
  const message = useSelector((state) => state.alert);

  return (
    <form className={"mt-5"} onSubmit={handleSubmit}>
      <h3>Account Recovery</h3>
      <p>Please enter the email associated with your account</p>
      {message ? (
        <div className={message.type + " my-1"}>
          {" "}
          <div className="popup"> {message.message}</div>
        </div>
      ) : (
        ""
      )}
      <div className="form-group">
        <input
          name="email"
          onChange={handleChange}
          type="email"
          className="form-control"
          placeholder="Enter email"
          required
        />
      </div>

      <button type="submit" className="btn btn-dark btn-lg btn-block">
        {loading ? (
          <div className="spinner-grow text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div> Proceed</div>
        )}
      </button>
      <br />
      <Link to="/EJohns" className="text-dark">
        <p className="forgot-password text-right">
          <strong> Login Page</strong>
        </p>
      </Link>
    </form>

  );
}
export default AccountRecovery;
