export const adminConstants = {

    PORTAL_USER_RETRIEVE_REQUEST: 'PORTAL_USER_RETRIEVE_REQUEST',
    PORTAL_USER_RETRIEVE_SUCCESS: 'PORTAL_USER_RETRIEVE_SUCCESS',
    PORTAL_USER_RETRIEVE_FAILURE: 'PORTAL_USER_RETRIEVE_FAILURE',


    PORTAL_USER_RESET_PIN_REQUEST: 'PORTAL_USER_RESET_PIN_REQUEST',
    PORTAL_USER_RESET_PIN_SUCCESS: 'PORTAL_USER_RESET_PIN_SUCCESS',
    PORTAL_USER_RESET_PIN_FAILURE: 'PORTAL_USER_RESET_PIN_FAILURE',
    PORTAL_USER_RESET_PIN_CLEAR: 'PORTAL_USER_RESET_PIN_CLEAR',
    PORTAL_USER_UPDATE_REQUEST: 'PORTAL_USER_UPDATE_REQUEST',
    PORTAL_USER_UPDATE_SUCCESS: 'PORTAL_USER_UPDATE_SUCCESS',
    PORTAL_USER_UPDATE_FAILURE: 'PORTAL_USER_UPDATE_FAILURE',

    PORTAL_ACCOUNT_EDIT_REQUEST: 'PORTAL_ACCOUNT_EDIT_REQUEST',
    PORTAL_ACCOUNT_EDIT_SUCCESS: 'PORTAL_ACCOUNT_EDIT_SUCCESS',
    PORTAL_ACCOUNT_EDIT_FAILURE: 'PORTAL_ACCOUNT_EDIT_FAILURE',
    PORTAL_ACCOUNT_EDIT_CLEAR: 'PORTAL_ACCOUNT_EDIT_CLEAR',


};