/**
 * @author Kevin Gombe
 * @email  amkevin99@gmail.com
 * @create date 2021-02-05 12:13:32
 * @modify date 2021-02-05 12:14:36
 * @desc
 */

import React from 'react';
import ReactDOM from 'react-dom';

 import App from './App';
 import './index.css';
 import './css/demo.css';
import './css/theme-blue.css'
import 'bootstrap/dist/css/bootstrap.css';
 import './vendor/font-awesome/css/font-awesome.min.css';
 import './vendor/animate-css/vivify.min.css';
 import './css/site.min.css';
import { Provider } from 'react-redux';
import {store} from "./shared/store";

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// //STORE :Globalised state for whole application

// import reportWebVitals from './reportWebVitals';
// import {createStore} from 'redux';
// //ACTION :Describes what you want to do eg handleInrement  :::it is a function that returns an object
// const increment =()=> {
//     return{
//         type:"INCREMENT"
//     }
// }
// const decrement =()=> {
//     return{
//         type:"DECREMENT"
//     }
// }
// //REDUCER :how your action transforms state into the next state
// // checks which action has been requested and based on that will modify store
// const counter =(state = 0,action)=>{
//     switch (action.type){
//         case 'INCREMENT':
//             return state +1;
//         case 'DECREMENT':
//             return state -1;
//     }
// };
// let store = createStore(counter);
// //DISPATCH : How we can call an action ,eg dispatch this action to the reducer
// store.dispatch(increment());



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
